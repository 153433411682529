<!--
 * @Description: helpContent
 * @Author: LinFeng
 * @Github: https://github.com/LinFeng-tt
 * @Date: 2021-05-26 16:40:16
 * @LastEditors: yanxiao
-->
<!--  -->
<template>
  <div class='body'>
    <van-nav-bar :title="supportTitle"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row style="width: 100%;height: calc(100% - 70px);background-color: #ffffff;">
      <van-row class="content"
               v-html="content"></van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      supportTitle: this.$route.query.supportTitle,
      content: this.$route.query.content

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getHelpCenterList()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 返回
    handleClickTopBack () { this.$router.go(-1) }

    // // 获取帮助中心列表
    // getHelpCenterList () {
    //   const info = {
    //     supportTitle: '',
    //     pageNum: 1,
    //     pageSize: 5
    //   }
    //   this.$helpCenter.querySupportList(info).then(res => {
    //     console.log(res)
    //     this.helpContentList = res.resultEntity.list
    //   })
    // }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .title {
    height: 40px;
    // width: 94%;
    line-height: 40px;
    // margin: 0 3%;
    border-bottom: 1px rgb(109, 106, 106) solid;
    padding-left: 3%;
  }
  .content {
    // height: calc(100% -46px - 40px - 20px);
    padding: 10px 3%;
  }
}
</style>
